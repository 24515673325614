export const Footer = () => {
    return (
        <footer>
            <div className='footer__contact_information'>
                <div className="">Developer contact information <a href='tel:+48733026757'>+48733026757</a></div>
                <div className="">email <a href='mailto:weronikamikolak@gmail.com'>weronikamikolak@gmail.com</a>
            </div>
            </div>
            <div className="footer__copyright">Fantik is official trademark belonging to <span>Asura Engineering Sp. z o.o.</span>
            </div>
            <div className="footer__copyright">Fantik Android and iOS apps are developed by <span>Asura Engineering Sp. z o.o.</span>
            </div>
        </footer>
    )
}