import logo from '../assets/images/logo.svg'

import '../styles/variables.scss'
import '../styles/zeroing.scss'
import '../styles/style.scss'

import screenshot11 from '../assets/images/11.jpg';
import screenshot12 from '../assets/images/12.jpg';
import screenshot14 from '../assets/images/14.jpg';

const URL_GOOGLE_PLAY = null; //"https://play.google.com/store/apps/details?id=com.tikboost.fantik"
const URL_APP_STORE = "https://apps.apple.com/us/app/fantik-real-followers-likes/id6448748483"

export const HomePage = () => {
    return (
        <main className="main">
            <section className="container">
                <header className="header">
                    <img
                        src={logo}
                        alt="Logo"
                        className="logo__img"
                    />
                    <p>FanTik</p>
                </header>
                <section className="wrapper">
                    <div className="about__activity">
                        <h1 className="about__activity_title">
                            Become more popular with us
                        </h1>
                        <p className="about__activity_text">
                            Unlock exclusive analytics on TikTok users, followers and likes
                        </p>

                        <div className="buttons__links">
                            {URL_APP_STORE !== null && <a href={URL_APP_STORE} className="btn btn_appStore">
                                <p>App Store</p>
                            </a>}
                            {URL_GOOGLE_PLAY !== null && <a href={URL_GOOGLE_PLAY} target="_blank" rel="noreferrer nofollow" className="btn btn_googlePlay">
                                <p>Google Play</p>
                            </a>}
                        </div>
                    </div>
                    <div className='screenshots_wrapper'>
                        <img className='screenshot_img' src={screenshot11}  alt='screenshot' />
                        <img className='screenshot_img' src={screenshot12} alt='screenshot' />
                        <img className='screenshot_img' src={screenshot14} alt='screenshot' />
                    </div>
                </section>
            </section>
        </main>
    )
}